@import './../../styles/variables.scss';

.nav{
  position: fixed;
  width: 250px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  background: $white2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: width 0.5s, left 0.5s;

  &__list{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 40px;

    &__item{
      position: relative;
      width: 100%;
      list-style: none;

      a{
        position: relative;
        display: block;
        width: 100%;
        margin: 30px 0;
        white-space: normal;
        display: flex;
        text-transform: uppercase;
        text-decoration: none;
        color: $black;
        font-weight: 500;
        font-size: 1.1em;
        letter-spacing: 2px;
        opacity: 0.55;
      }

      a:hover{
        opacity: 1;
      }
    }
  }
}

.hideNav {
  width: 0;
}

.themeSwitch{
  position: absolute;
  bottom: 20px;
  left: 40px;
  width: 30px;
  height: 30px;
  background: $black;
  color: $white;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.themeSwitch::before{
  // content: '\f186';
  // font-family: fontAwesome;
}

.themeSwitch::after{
  content: 'Switch to dark mode';
  position: absolute;
  left: 40px;
  font-size: 0.7em;
  color: $black;
  white-space: nowrap;
  font-family: sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  pointer-events: none;
}

@media (max-width: 992px){
  .nav{
    left: -100%;
  }
  .hideNav {
    left: 0;
    width: 100%;
  }
  .nav__list{
    padding: 20px 20px;
  }
}