@import './../../styles/variables.scss';

section{
  position: relative;
  min-height: 100vh;
}

.hero{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.wrapper {
  width: 70%;
  margin: auto;
  // margin-top: 120px;
}

// .logo {
//     font-size: 26px;
//     color: white;
//     width: 170px;
//     height: 50px;
//     background-color: #262626;
//     text-align: center;
//     margin-bottom: 120px;
//     span{
//       display: inline-block;
//       vertical-align: middle;
//       line-height: 50px;
//     }
// }

.desc {
  color: #262626;
  font-size: 36px;
  line-height: 56px;
  letter-spacing: 2px;
  font-weight: 400;
}

.socialMedia{
  position: absolute;
  bottom: 20px;
  display: flex;

  li{
    list-style: none;
    :hover{
        background: $black2;
        color: $white;
    }
    button{
      
      width: 100%;
      padding: 10px;
      border: none;
      outline: none;
      margin: 5px;
      color: $black;
      background: rgba(0,0,0,.05);
      font-size: 16px;
      display: flex;
      // border: 1px solid rgba(0,0,0,0.1); 
      justify-content: center;
      align-items: center;       
    }

    a{
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0,0,0,.05);
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $black;
      font-size: 1.2em;

      
    }
  }
}
