@import './../../styles/variables.scss';

.topbar{
  position: fixed;
  top:0;
  width: calc(100% - 250px);
  left: 250px;
  background: $white2;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  height: 60px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: width 0.5s, left 0.5s;
}

.hideNav{
  width: 100%;
  left: 0px;
}


.logo {
  color: $black;
  font-weight: 700;
  text-decoration: none;
  font-size: 1.4em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $black;
}

@media (max-width: 992px){
  .topbar{
    width: 100%;
    left: 0;
    padding: 0 20px;
  }
  // .hideNav{
  //   left: 100%;
  // }
}