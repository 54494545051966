@import './../../styles/variables.scss';


.adjust{
  position: relative;
  min-height: 100vh;
  padding: 0 40px;
  padding-top: 80px;
  background: $white2;
  scroll-margin-top: -10vh;
}
.title{
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  h2{
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

.title h2::before{ 
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -10px;
  width: 50px;
  height: 1px;
  background: $black;
}

.content{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 20px;
  margin: 40px 0;

  &__box{
    border: 1px solid rgba(0,0,0,0.5);
    padding: 50px 20px;
    display: flex;
    background: $white2;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      max-width: 50px;
    }
    h2{
      font-size: 1.1em;
      margin-top: 20px;      
      margin-bottom: 10px;
      font-weight: 600;
      color: $black;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    p{
      text-align: center;
    }
  }
}

@media (max-width: 992px){
  .content{
    grid-template-columns: repeat(2,1fr);
  }
}

@media (max-width: 767px){
  .content{
    grid-template-columns: repeat(1,1fr);
  }
}