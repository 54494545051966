@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');

//fonts
$raleway: 'raleway', sans-serif;


// colors
$black: #333;
$black2: #333;
$white: #fff;
$white2: #fff;