@import "./reset.scss";
@import "./variables.scss";


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $raleway;
    scroll-behavior: smooth;
}

body{
    overflow-x: hidden;
}
