@import './../../styles/variables.scss';

.adjust{
  position: relative;
  min-height: 70vh;
  padding: 0 40px;
  padding-top: 80px;
  background: $white2;
}

.title{
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  h2{
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

.title h2::before{ 
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -10px;
  width: 50px;
  height: 1px;
  background: $black;
}

.content{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  &__text{
    width: 49%;
    min-width: 49%;

    p{
      font-size: 1em;
      letter-spacing: 1px;
      font-weight: 400;
      line-height: 23px;
    }
  }
  &__projectImg{
    border: 1px solid rgba(0,0,0,0.5);
  }
  &__imgBoxL{
    margin-left: 40px;
    
    img{
      max-width: 100%;
    } 
  }
  &__imgBoxR{
    margin-right: 40px;
    img{
      max-width: 100%;
    } 
  }
}
.linkContainer{
  text-align: center;
}
.demoLink{
  display: inline-block;
  margin: 20px;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: underline;
  color: black;
  text-decoration: none;
}

@media (max-width: 992px){
  .adjust{
    padding: 0 20px;
    padding-top: 80px;
  }
  .content{
    flex-direction: column;
    &__text{
      width: 100%;
      min-width: 100%;
    }
    &__imgBoxR{
      margin-left: 0px;
      margin-top: 20px;
    }
    &__imgBoxL{
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

