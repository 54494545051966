@import './../../styles/variables.scss';

.adjust{
  position: relative;
  min-height: 60vh;
  width: 100%;
  padding: 0 40px;
  padding-top: 80px;
  background: $white2;
  display: inline-block;
  scroll-margin-top: -10vh;
}
.title{
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  h2{
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

.title h2::before{ 
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -10px;
  width: 50px;
  height: 1px;
  background: $black;
}

.content{
   display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 20px;
  margin: 40px 0;
  // width: 100%;

  &__workBox{
    border: 1px solid rgba(0,0,0,0.5);
    position: relative;
    height: 300px;
    width: 100%;
    overflow: hidden;

    .imgBox{
      position: relative;
      width: 100%;
      height: 100%;
      transform: translateY(0);
      transition: 0.5s ease-in-out;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .textBox{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      transform: translateY(100%);
      transition: 0.5s ease-in-out;
      text-align: center;
      h3{
        padding: 40px 0px;
        color: #fff;
        font-size: 1.5em;
      }
      p{
        display: inline-block;
        color: #fff;
        font-size: 1em;
        padding: 0 20px;
        padding-bottom: 20px;
      }
      a{
        // padding: 20px 20px;
        // white-space: normal;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        color: $white;
        // font-weight: 400;
        font-size: 1em;
        justify-content: center;
        align-items: center;
        // grid-template-columns: repeat(2,1fr);
      }
    }
    
  }
  &__workBox:hover{
    .imgBox{
      transform: translateY(-100%);
    }
    .textBox{
      transform: translateY(0);
    }
  }
}

@media (max-width: 992px){
  .content{
    grid-template-columns: repeat(2,1fr);
  }
}

@media (max-width: 767px){
  .content{
    grid-template-columns: repeat(1,1fr);
  }
}