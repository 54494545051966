@import './../../styles/variables.scss';

.main {
  position: absolute;
  width: calc(100% - 250px);
  left: 250px;
  background-color: $white;
  min-height: 100vh;
  transition: width 0.5s, left 0.5s;
}

.hideNav{
  width: 100%;
  left: 0;
}

@media (max-width: 992px){
  .main{
    width: 100%;
    left: 0;
  }
  .hideNav{
    left: 100%;
  }
}