@import './../../styles/variables.scss';

.adjust{
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0 40px;
  padding-top: 80px;
  background: $white2;
  display: inline-block;
}
.title{
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  h2{
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

.title h2::before{ 
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -10px;
  width: 50px;
  height: 1px;
  background: $black;
}

.contact{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @media (max-width: 767px){
    margin-bottom: 40px;
  }

  .contactForm{
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-top: 20px;

    &__row{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 20px;
      margin-top: 20px;

      @media (max-width: 767px){
        grid-template-columns: repeat(1,1fr);
      }
      input{
        width: 100%;
        padding: 10px;
        border: none;
        outline: none;
        color: $black;
        background: $white2;
        font-size: 16px;
        border: 1px solid rgba(0,0,0,0.1);        
      }
    }

    &__row2{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      textarea{
        width: 100%;
        padding: 10px;
        border: none;
        outline: none;
        color: $black;
        background: $white2;
        font-size: 16px;
        border: 1px solid rgba(0,0,0,0.1);
        resize: none;  
        height: 200px;
      }
      input{
        background: $black2;
        color: #fff;
        padding: 10px 30px;
        display: inline-block;
        font-weight: 500;
        letter-spacing: 2px;
        cursor: pointer;
        font-size: 16px;
        text-transform: uppercase;
        max-width: 150px;
        border: none;
      }
    }
  }
}

